import React, {createContext, useContext} from 'react';

import {getLanguageName} from '@helpers/getLanguageInfo';

import pageLanguageCodes from '../../config/languageCodes.json';

const LanguageContext = createContext(null);

export function LanguageContextProvider({
    pageLanguageCode,
    pageBasePath,
    children
}) {
    const pageLanguagePaths = pageLanguageCodes[pageBasePath];

    if (typeof pageLanguagePaths === 'undefined') {
        throw new Error('The URL does not have any languages associated.');
    }

    const pageLanguageName = getLanguageName(pageLanguageCode);

    return (
        <LanguageContext.Provider
            value={{
                pageBasePath,
                pageLanguagePaths,
                pageLanguageCode,
                pageLanguageName
            }}
        >
            {children}
        </LanguageContext.Provider>
    );
}

export const useLanguageContext = () => useContext(LanguageContext);
