import React from 'react';
import getHrefLangTags from '@helpers/getHrefLangTags';
import {useLanguageContext} from '@helpers/LanguageContext';
import GetSiteMetadata from '@src/hooks/get-site-metadata';
import Helmet from 'react-helmet';
import BreadcrumbSchema from './BreadcrumbSchema';

const SEO = (props) => {
    const {pageBasePath, pageLanguagePaths, pageLanguageCode} = useLanguageContext();

    const {pageTitle, pageMeta, breadcrumb, errorPageTitle} = props;

    const returnErrorTitle = (seoPageTitle) => {
        if (seoPageTitle !== null && typeof seoPageTitle !== 'undefined') {
            return (
                <title>{seoPageTitle}</title>
            );
        }
        return null;
    };

    // Override pageTitle with seoMetaTitle if set in CMS.
    const title = pageMeta && pageMeta.seoMetaTitle ? pageMeta.seoMetaTitle : pageTitle;

    // Remove any null properties from the pageMeta object.
    const cleanPageMeta = Object.keys(pageMeta || {}).reduce((acc, i) => {
        if (pageMeta[i] != null) {
            acc[i] = pageMeta[i];
        }
        return acc;
    }, {});

    // Get siteMetadata from graphQL to use as default values.
    const {
        site: {
            siteMetadata: {description, keywords, ogimage, siteUrl}
        }
    } = GetSiteMetadata();

    // Create siteMeta object (using same property names as pageMeta object).
    const siteMeta = {
        seoMetaDescription: description,
        seoMetaKeywords: keywords,
        seoOgTitle: title,
        seoOgDescription: description,
        seoOgImage: ogimage
    };

    // Merge pageMeta and siteMeta.
    // Null or missing pageMeta values will default to siteMeta values.
    const meta = Object.assign(siteMeta, cleanPageMeta);

    // This function checks whether the environment is production
    // and if not it returns the no index meta tag.
    const noIndex = (environment) => {
        if (environment !== 'production') {
            return <meta name="robots" content="noindex" />;
        }
        if (environment === 'production' && meta.seoNoindex === true) {
            return <meta name="robots" content="noindex" />;
        }
        return null;
    };

    return (
        <>
            <Helmet title={meta.seoMetaTitle}>
                {returnErrorTitle(errorPageTitle)}
                {/* TODO: it probalby shouldn't be pageLanguageCode */}
                <html lang={pageLanguageCode} />
                {getHrefLangTags({pageBasePath, pageLanguagePaths})}
                <meta name="description" content={meta.seoMetaDescription} />
                <meta name="keywords" content={meta.seoMetaKeywords} />

                <meta property="og:title" content={meta.seoOgTitle} />
                <meta property="og:description" content={meta.seoOgDescription} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={meta.seoOgImage} />

                {pageMeta && pageMeta.seoCanonical && <link rel="canonical" href={pageMeta.seoCanonical} />}
                {noIndex(process.env.GATSBY_GTM_SITE_ENVIRONMENT)}
            </Helmet>
            <BreadcrumbSchema breadcrumb={breadcrumb} siteUrl={siteUrl} />
        </>
    );
};

export default SEO;
