import React from 'react';
import Helmet from 'react-helmet';

const BreadcrumbSchema = ({siteUrl, breadcrumb}) => {

    // Check the breadCrumb object and return null if its empty.
    // This has been added do to the custom 404 functionality does not contain the breadcrumb information.
    if (!breadcrumb) {
        return null;
    }

    const crumbs = breadcrumb.crumbs.map((crumb, index) => {
        return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
                '@id': `${siteUrl}${crumb.pathname}`,
                'name': `${crumb.crumbLabel}`
            }
        };
    });

    const schema = JSON.stringify({
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        'itemListElement': crumbs
    });

    return (
        <Helmet>
            <script type="application/ld+json">
                {`${schema}`}
            </script>
        </Helmet>
    );
};

export default BreadcrumbSchema;
