/**
 * @param {string} language String e.g japan.
 * @returns {string} Returns the same parapater that was put into the function but with a capitalised first letter.
 * @description This function capitalises the string by making the first character uppercase.
 */
const capitaliseText = (language) => {

    // Make the first character uppercase.
    const firstCharacter = language.charAt(0).toUpperCase();

    // remove the first character from the string.
    const allButFirstCaracter = language.slice(1);

    return firstCharacter + allButFirstCaracter;
};

export default capitaliseText;
