import React, {useState} from 'react';
import Button from '@components/atoms/Button';
import IconLink from '@components/atoms/IconLink';
import Modal from '@components/molecules/Modal';
import {useLanguageContext} from '@helpers/LanguageContext';
import headerSettings from '@netlify/site-settings/header.yml';
import headerTextConfig from '@netlify/site-settings/hudl-us-popup.yml';
/* eslint-disable */
import IconNewWindow from '-!svg-react-loader!@images/icon-new-window.svg';
/* eslint-enable */

import './style.usSiteModal.scss';

const UsSiteModal = ({className = null}) => {
    const {pageLanguageName} = useLanguageContext();

    const [ isOpen, setIsOpen ] = useState(false);
    const classes = [ 'hudl-us-site-modal' ];

    return (
        <>
            <IconLink onClick={() => setIsOpen(true)} button>
                {headerSettings[pageLanguageName].headerPreNav1[0].label}
                <IconNewWindow />
            </IconLink>
            {isOpen && (
                <Modal onClose={() => setIsOpen(false)} className={className}>
                    <div className={classes.join(' ')}>
                        <h3 className="hudl-us-site-modal__title hudl-gamma-type hudl-margin-bottom--none">{headerTextConfig[pageLanguageName].heading}</h3>
                        <p className="hudl-us-site-modal__text hudl-margin-y--sm  hudl-margin-y--md--mobile">{headerTextConfig[pageLanguageName].content}</p>
                        <div className="hudl-us-site-modal__button-wrapper hudl-margin-top--sm">
                            <Button component="us_site_modal" variant="primary-button-dark" newPage="true" to="https://www.hudl.com" className="hudl-us-site-modal__button">{headerTextConfig[pageLanguageName].ctaText}</Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default UsSiteModal;
