import React from 'react';
import {Link} from 'gatsby';

import './style.icon-link.scss';

const IconLink = ({to, children, id=null, onClick=null, external=false, button=false, newWindow=false}) => {

    const LinkOrAnchor = () => {
        if (external) {
            const target = newWindow === true ? '_blank' : '_self';
            return (
                <a className="hudl-icon-link" href={to} id={id} onClick={onClick} target={target}>
                    {children}
                </a>
            );
        }
        if (button) {
            return (
                <button className="hudl-icon-link hudl-icon-link--button" type="button" id={id} onClick={onClick}>
                    {children}
                </button>
            );
        }
        return (
            <Link className="hudl-icon-link" to={to} id={id} onClick={onClick}>
                {children}
            </Link>
        );
    };

    return (
        <LinkOrAnchor />
    );
};

export default IconLink;
