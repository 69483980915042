// TODO: Swap out for loops for forEach loops.

import {useEffect, useState} from 'react';

function getCookie(name) {
    // eslint-disable-next-line prefer-template
    const value = '; ' + document.cookie;
    // eslint-disable-next-line prefer-template
    const parts = value.split('; ' + name + '=');

    return parts.length === 2;
}

/**
 * This callback type is called `stateCallback` and is displayed as a global symbol.
 *
 * @callback stateCallback
 * @param {boolean} stateUpdate
 */

/**
 * @description Watches an object and keeps firing until it finds the key name passed to it.
 * @param {Object} option
 * @param {Object} option.window
 * @param {stateCallback} option.callback
 * @param {string} option.key
 */
const proxyWindow = ({window, callback, key}) => {
    let props = Object.keys(window);
    let requestFrame = null;
    // eslint-disable-next-line consistent-return
    const check = () => {
        const currentProps = Object.keys(window);
        const newProps = currentProps.filter(item => props.indexOf(item) === -1);
        if (newProps.length) {
            if (newProps.includes(key)) {
                callback(true);
                window.cancelAnimationFrame(requestFrame);
            }

            props = currentProps;
        }
        requestFrame = requestAnimationFrame(check);
    };
    requestAnimationFrame(check);
};

const OneTrustHolder = ({page}) => {
    const [ loadedOptanon, setLoadedOptanon ] = useState(false);

    if (typeof window !== 'undefined' && loadedOptanon === false) {
        proxyWindow({window, callback: setLoadedOptanon, key: 'Optanon'});
    }

    if (typeof window !== 'undefined') {
        useEffect(() => {
            if (document.cookie.indexOf('OptanonAlertBoxClosed=') >= 0) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'OnetrustGroupsUpdated',
                    'OptanonAlertBoxClosed': 'true'
                });
            } else {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'OnetrustGroupsUpdated',
                    'OptanonAlertBoxClosed': 'false'
                });
            }

        }, [ page ]);

        useEffect(() => {
            if (loadedOptanon) {
                window.Optanon.Init();
                window.Optanon.LoadBanner();

                const toggleDisplay = document.getElementsByClassName('optanon-toggle-display');

                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < toggleDisplay.length; i++) {
                    toggleDisplay[i].onclick = (event) => {
                        event.stopImmediatePropagation();
                        window.Optanon.TriggerGoogleAnalyticsEvent(
                            'OneTrust Cookie Consent',
                            'Banner Open Preferences'
                        );
                        window.Optanon.ToggleInfoDisplay();
                    };
                }

                Array.from(document.getElementsByClassName('menu-item-about'))
                    .forEach(item => {
                        item.remove();
                    });
            }

            if (page.title !== '') {
                // Add previous origin to locations object.
                window.locations = window.locations || [ document.referrer ];
                window.locations.push(window.location.origin);
                window.previousOrigin = window.locations[window.locations.length - 2];

                const url = window.location.href;
                const baseUrl = window.location.origin;
                let newUrl = url.replace(baseUrl, '');

                // Remove the language code.
                newUrl = newUrl.split('/');
                newUrl.shift();
                newUrl.shift();
                newUrl = newUrl.join('/');

                const pageTitle = page.title;
                let pageType = 'General Page';
                const pageLanguage = document.documentElement.lang;
                const dataLayer = window.dataLayer || [];

                // Changes the pagetype based on the URL.
                switch (newUrl) {
                case '':
                    pageType = 'Homepage';
                    break;

                case 'products':
                    pageType = 'Product Overview Page';
                    break;

                case 'contact':
                    pageType = 'Contact';
                    break;

                case 'demo':
                    pageType = 'Book a Demo Page';
                    break;

                case 'blog':
                    pageType = 'Blog Overview Page';
                    break;

                case 'support':
                    pageType = 'Support';
                    break;

                case 'about':
                    pageType = 'About Page';
                    break;

                default:
                    if (newUrl.startsWith('case-study')) {
                        pageType = 'Case Study';
                    } else if (newUrl.startsWith('products/')) {
                        pageType = 'Individual Product Page';
                    } else if (newUrl.startsWith('blog/')) {
                        pageType = 'Blog Article';
                    }
                }

                // ToDo: Add functionality to switch the Language.
                const payload = {
                    event: 'page_load',
                    previous_page: window.HUDL.previousPath,
                    page_type: pageType,
                    page: pageTitle,
                    language: pageLanguage
                };

                const OneTrustGroups = window.dataLayer.filter(item => typeof item.OnetrustActiveGroups !== 'undefined');
                const OptanonGroups = window.dataLayer.filter(item => typeof item.OptanonActiveGroups !== 'undefined');

                if (OneTrustGroups.length > 0) {
                    payload.OnetrustActiveGroups = OneTrustGroups[0].OnetrustActiveGroups;
                }
                if (OptanonGroups.length > 0) {
                    payload.OptanonActiveGroups = OptanonGroups[0].OptanonActiveGroups;
                }

                // Filter out repeat renders.
                const checkForRepeat = window.dataLayer.filter(item => item.event !== 'page_load').pop();
                if (checkForRepeat.page !== pageTitle) {
                    dataLayer.push(payload);
                }
            }

        }, [ loadedOptanon, document ]);

    }
    return null;
};

export {
    getCookie,
    OneTrustHolder
};
