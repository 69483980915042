import React from 'react';
import FontLoader from '@components/atoms/FontLoader';
import SiteEnvVars from '@containers/EnvironmentVariables';
import {LanguageContextProvider} from '@helpers/LanguageContext';
import {OneTrustHolder} from '@helpers/oneTrustGTM';
import {window, document, exists} from 'browser-monads';
import {useScrollTracker} from 'react-scroll-tracker';

import {widgetCode} from '../../../netlify/site-settings/uberflip.yml';

import './style.index.scss';

const Layout = ({children, page}) => {

    if (exists(window)) {
        useScrollTracker([ 25, 50, 75, 100 ], ({scrollY, scrollPercent}) => {
            window.dataLayer.push({
                'event': 'scroll_tracking',
                'attributes': {
                    'pixels': `${(document.body.scrollHeight / 100) * scrollPercent}px`,
                    'distance': `${scrollY}%`,
                    'label': page.title
                }
            });
        });
    }

    return (
        <>
            <script type="text/javascript" src={`https://content.cdntwrk.com/components/website-widget/v1/${widgetCode}/widget.js`} async />
            <SiteEnvVars env={process.env.GATSBY_GTM_SITE_ENVIRONMENT} />
            <FontLoader language={page.language} />
            <LanguageContextProvider pageLanguageCode={page.language} pageBasePath={page.path}>
                <div>
                    <OneTrustHolder page={page} />
                    <main className="hudl-main-container">{children}</main>
                </div>
            </LanguageContextProvider>
        </>
    );
};
export default Layout;
