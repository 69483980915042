import React from 'react';
import {Link} from 'gatsby';

const TrackingLink = ({
    className = '',
    children,
    to,
    newPage = 'false',
    component = ''
}) => {
    let dataLayerPushRequired = false;
    let dataLayerPushEvent = '';

    const pushToDataLayer = (e, dataLayerEvent, dataLayerSecondValue) => {
        switch (dataLayerEvent) {
        case 'book_a_demo_button':
            window.dataLayer.push({
                event: dataLayerEvent,
                component: dataLayerSecondValue
            });
            break;

        default:
            window.dataLayer.push({
                event: dataLayerEvent,
                link: dataLayerSecondValue
            });
        }
    };

    // Check link to see if dataLayer push is required.
    // This will match '/demo' and 'demo' for the url.
    if (/\/?demo$/g.exec(to)) {
        dataLayerPushRequired = true;
        dataLayerPushEvent = 'book_a_demo_button';
    }

    if (newPage === 'true') {
        dataLayerPushRequired = true;
        // Determine if the link is to a Hudl website or not.
        dataLayerPushEvent = to.includes('hudl.com') ? 'click_to_external_hudl' : 'click_to_external_site';
    }
    // Fix relative url's that don't start a slash.
    const url = !to.startsWith('http') && !to.startsWith('/') ? `/${to}` : to;

    // Must use <a> as target="_blank" does not work on Gatsby Link.
    // Internal links that have been selected to open in a new tab/window are passing
    // through string or boolean so both are been checked for.
    if (newPage === 'true' || newPage === true) {
        return (
            <a onClick={dataLayerPushRequired ? (e) => pushToDataLayer(e, dataLayerPushEvent, to) : null} href={url} target="_blank" rel="noopener noreferrer" className={className}>
                {children}
            </a>
        );
    }
    return (
        <Link onClick={dataLayerPushRequired ? (e) => pushToDataLayer(e, dataLayerPushEvent, component) : null} className={className} to={url}>
            {children}
        </Link>
    );
};

export default TrackingLink;
