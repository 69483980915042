import React, {useEffect, useState} from 'react';
import {getLanguageFonts} from '@helpers/getLanguageInfo';
import GoogleFontLoader from 'react-google-font-loader';

const FontLoader = ({language}) => {
    const [ loaded, setLoaded ] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return loaded ? (
        <GoogleFontLoader fonts={getLanguageFonts(language)} />
    ) : null;
};

export default FontLoader;
