import React, {useContext} from 'react';
import {ModalContext} from '@src/contextModels/ModalContext';
import {createPortal} from 'react-dom';

import './style.modal.scss';

const Modal = ({children, ...props}) => {
    const modalNode = useContext(ModalContext);

    if (modalNode) {
        return createPortal(
            <ModalWrapper {...props}>
                {children}
            </ModalWrapper>,
            modalNode
        );
    }

    return null;
};

const ModalWrapper = ({
    children,
    className=null,
    onClose
}) => {

    const classes = [ 'hudl-modal__overlay', 'hudl-modal--open', className ];
    return (
        <div className={classes.join(' ')}>
            <aside role="dialog" className="hudl-modal__container hudl-text-align--center hudl-padding--sm hudl-padding--lg--mobile">
                <button onClick={onClose} className="hudl-modal__close-button" aria-label="Close modal" type="button" />
                <div className="hudl-modal__content">
                    {children}
                </div>
            </aside>
        </div>
    );
};

Modal.defaultProps = {
    type: 'modal'
};

export default Modal;
