import React from 'react';

import {defaultLanguageInfo} from '@helpers/getLanguageInfo';

const getHrefLangTags = ({pageBasePath = '', pageLanguagePaths = []}) => {
    const path = pageBasePath === 'home' ? '' : pageBasePath;

    const getSeoCode = languagePath => {
        const languageCode = Object.keys(defaultLanguageInfo.languages).find(
            key => languagePath === defaultLanguageInfo.languages[key].path
        );

        return defaultLanguageInfo.languages[languageCode].seoCode;
    };

    return pageLanguagePaths.map(languagePath => (
        <link
            key={languagePath}
            rel="alternate"
            hrefLang={getSeoCode(languagePath)}
            href={`${defaultLanguageInfo.url}/${languagePath}/${path}`}
        />
    ));
};

export default getHrefLangTags;
