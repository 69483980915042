import React from 'react';
import Button from '@components/atoms/Button';
import Container from '@components/atoms/Container';
import {useLanguageContext} from '@helpers/LanguageContext';
import footerSettings from '@netlify/site-settings/footer.yml';

/* eslint-enable */
import './style.newsletter-signup.scss';

const NewsletterSignup = () => {
    const {pageLanguageName} = useLanguageContext();
    return (
        <div className='hudl-newsletter-signup'>
            <Container>
                <div className="hudl-newsletter-signup__box-container">
                    <h3 className="hudl-newsletter-signup__title hudl-gamma-type">{footerSettings[pageLanguageName].newsletterSignupTitle}</h3>
                    <Button className="hudl-newsletter-signup__button" component="footer" variant="primary-button-dark" to={footerSettings[pageLanguageName].newsletterSignupButtonLink}>{footerSettings[pageLanguageName].newsletterSignupButtonText}</Button>
                </div>
            </Container>
        </div>
    );
};

export default NewsletterSignup;
