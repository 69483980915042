import React, {useState} from 'react';

import Button from '@components/atoms/Button';
import IconLink from '@components/atoms/IconLink';
import Modal from '@components/molecules/Modal';
import capitaliseText from '@helpers/capitaliseText';
import {defaultLanguageInfo, getLanguageName} from '@helpers/getLanguageInfo';
import {useLanguageContext} from '@helpers/LanguageContext';
import headerSettings from '@netlify/site-settings/header.yml';
/* eslint-disable */
import IconGlobe from '-!svg-react-loader!@images/icon-globe.svg';

import './style.language-picker.scss';

const getLanguageLabelInCurrentLanguage = (languagePath, currentLanguageAsText) => {
    const languageCode = Object.keys(defaultLanguageInfo.languages)
        .find(key => languagePath === defaultLanguageInfo.languages[key].path)

    const pageLanguageName = getLanguageName(languageCode);

    return headerSettings[currentLanguageAsText].languagePicker[0][pageLanguageName]
}

const LanguagePicker = ({ className = null }) => {
    const { pageLanguagePaths, pageLanguageName } = useLanguageContext();

    const defaultLanguagePath = Object.values(defaultLanguageInfo.languages)[0].path

    const [languageSelected, setLanguageSelected] = useState(defaultLanguagePath);

    const handleLanguageChange = (e) => {
        setLanguageSelected(e.target.value);
    }

    const [isOpen, setIsOpen] = useState(false);
    const classes = ['hudl-language-picker'];

    const languagePickerOptions = pageLanguagePaths.map(languagePath =>
        <option key={languagePath} value={languagePath} selected={languageSelected === languagePath}>
            {getLanguageLabelInCurrentLanguage(languagePath, pageLanguageName)}
        </option>
    )

    const formSubmit = ({ e, selectedLanguage }) => {
        // Preventing default so that the form doesn't submit.
        e.preventDefault();
        // Getting the window location pathname and split it by forward slash.
        const windowPath = window.location.pathname.split('/');
        // Shifting once to remove the empty array item that split adds.
        windowPath.shift();
        // Shifting again to remove the language path from the array.
        windowPath.shift();
        // Navigating to the same path, but with the selected language folder.
        window.location = `/${selectedLanguage}/${windowPath.join('/')}`;
    }

    return (
        <>
            <IconLink onClick={() => setIsOpen(true)} button>
                <IconGlobe />
                <span>
                    {headerSettings[pageLanguageName].languagePicker[0].currentLanguage}
                </span>
            </IconLink>
            {isOpen && (
                <Modal onClose={() => setIsOpen(false)} className={className}>
                    <div className={classes.join(" ")}>
                        <h3 className="hudl-gamma-type hudl-margin-top--none">{headerSettings[pageLanguageName].languagePicker[0].title}</h3>
                        <form className="hudl-language-picker__form" onSubmit={(e) => {formSubmit({e, selectedLanguage: languageSelected})}}>
                            <label className="hudl-language-picker__label hudl-text-align--left" htmlFor="language">
                                <p className="hudl-margin-bottom--sm">
                                    {`${headerSettings[pageLanguageName].languagePicker[0].language}: ${capitaliseText(headerSettings[pageLanguageName].languagePicker[0][pageLanguageName])}`}
                                </p>
                                <select onBlur={handleLanguageChange} className="hudl-language-picker__select" id="language">
                                    <option defaultValue disabled>{headerSettings[pageLanguageName].languagePicker[0].selectYourLanguage}</option>
                                    {languagePickerOptions}
                                </select>
                            </label>
                            <Button component="language_picker" variant="primary-button-dark" className="hudl-margin-top--xl" type="submit" />
                        </form>
                    </div>
                </Modal>
            )}
        </>
    );
};


LanguagePicker.defaultProps = {
    type: "language-picker"
};

export default LanguagePicker;
